/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

body {
    font-feature-settings: normal;
}

.custom-tab-content {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.xx-datepicker {
    margin-left: 0 !important;
    min-width: 14rem !important;
}

/* mat-snack-bar-container > div {
    padding: 0.2rem 1rem;
} */

.green-snack-bar > div {
    background-color: #89e589 !important;

    .mat-mdc-snack-bar-label {
        color: white;
    }
}

.red-snack-bar > div {
    background-color: #fe8b8b !important;

    .mat-mdc-snack-bar-label {
        color: black;
    }
}

.blue-snack-bar > div {
    background-color: #cde4fd !important;

    .mat-mdc-snack-bar-label {
        color: black;
    }
}

.fc-header-toolbar {
    font-size: 14px; /* Уменьшение размера шрифта */
}

/* Стиль для кнопок в заголовке */
.fc .fc-button-primary {
    padding: 0.4em 1em; /* Уменьшение отступов */
    font-size: 14px; /* Уменьшение размера шрифта кнопок */
}

/* Стиль для названия месяца и кнопки сегодня */
.fc .fc-toolbar-title {
    font-size: 16px; /* Установка размера шрифта для заголовка */
}

/* Дополнительное пространство между кнопками и заголовком */
.fc .fc-toolbar-chunk {
    margin-right: 10px; /* Установка отступа справа */
}

.highlight {
    background-color: #e3e4e6; /* Цвет подсветки, настраиваемый по вашему усмотрению */
    border-radius: 4px; /* Добавление скругления, если нужно */
}

.table-vertical-align-m {
    tbody {
        vertical-align: middle;
    }
}

.lisa-tab-group {
    .mat-mdc-tab {
        @apply h-10 #{!important};
    }
    .mat-mdc-tab.mdc-tab--active {
        @apply bg-primary text-on-primary #{!important};
        .mdc-tab__text-label {
            @apply text-on-primary transition-none #{!important};
        }
    }
}


/*  @ Paginator
/* -------------------------------------------------------------------------- */
.lisa-paginator.mat-mdc-paginator {
    justify-content: flex-end;
    .mat-mdc-paginator-container {
        justify-content: right;

        .mat-mdc-paginator-page-size,
        .mat-mdc-select-value,
        .mat-mdc-paginator-range-label {
            font-size: 12px !important;
            font-weight: 400 !important;
            @apply text-secondary #{!important};
        }

        @screen sm {
            justify-content: right;
        }
    }
}

.lisa-table {
    .mdc-data-table__row {
        height: 112px;
    }

    .mdc-data-table__cell {
        @apply text-base #{!important}
    }

    .mdc-data-table__header-cell {
        @apply text-lg text-hint font-normal #{!important}
    }

    .mat-mdc-row:hover .mat-mdc-cell {
        @apply bg-hover cursor-pointer #{!important}
    }
}

.lisa-button-toggle-group.mat-button-toggle-group {
    mat-button-toggle.mat-button-toggle-checked {
        @apply bg-primary #{!important};
        .mat-button-toggle-label-content {
            @apply text-on-primary #{!important};
        }
    }
    mat-button-toggle {
        .mat-button-toggle-label-content {
            text-wrap: wrap;
            line-height: normal !important;
            padding: 12px;
        }
    }
}


@media (max-width: 560px) {
    .my-dialog {
        width: 100% !important;
    }
}

img {
    width: auto;
}
