/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
//@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=block');
@import "malva-theme.scss";
@import "../assets/fonts/FixelDisplay/FixelDisplay.css";

.audio-modal-dialog {
    .mat-mdc-dialog-container {
       .mdc-dialog__surface {
            padding: 0;
            background-color: #0F172A;
        }
    }
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
    display: block !important;
}

.mat-mdc-form-field {
    .mat-mdc-form-field-error-wrapper {

        padding-left: 4px !important;

        .mat-mdc-form-field-error {
            display: inline;
            margin-left: -4px;
        }
    }
}

.text-link {
    font-weight: 500;
    &:hover {
        color: #0c23b1 !important;
    }
}

.cdk-overlay-container{
    z-index: 900; //lower then fixed header z-index so it goes behind it
}

.app-image-review {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 9999;

    .image {
        width: 100%;
        height: auto;
        transition: transform 0.3s;
    }
}

.mat-mdc-dialog-container {
    .mdc-dialog__surface {
        box-shadow: none !important;
    }
}

ngx-mat-color-picker-content {
    .ngx-mat-color-canvas {

        .color-canvas-row {

            .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) {
                margin-top: 22px;
            }

            .mat-mdc-form-field {
                margin-top: 6px;

                .mat-mdc-form-field-subscript-wrapper
                {
                    display: none;
                }
            }

            &:first-of-type {
                margin-bottom: 8px;
            }

            &:nth-of-type(2) {
                .mat-mdc-form-field {

                    .mat-mdc-form-field-flex
                    {
                        padding: 0;
                    }
                }
            }

            .zone-canvas {
                display: flex;
            }
            .zone-inputs {
                margin-top: 0;
            }
        }
    }

    .ngx-mat-color-collection {

        .color-collection-row {
            margin-top: 4px;

            &:first-of-type {
                margin-top: 8px;
            }

            button.btn-color {
                min-height: auto;
            }
        }
    }
}

mat-expansion-panel {
    box-shadow: 0px 0px 12px 0px #9BBCDD4D !important;
    border: 1px solid var(----fuse-border, #E2E8F0);
}
