@font-face {
    font-family: 'Fixel Display';
    font-weight: normal;
    font-style: normal;
    src:
        url('/assets/fonts/FixelDisplay/FixelDisplay-Regular.woff2') format('woff2'),
        url('/assets/fonts/FixelDisplay/FixelDisplay-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Fixel Display';
    font-weight: normal;
    font-style: italic;
    src:
        url('/assets/fonts/FixelDisplay/FixelDisplay-RegularItalic.woff2') format('woff2'),
        url('/assets/fonts/FixelDisplay/FixelDisplay-RegularItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Fixel Display';
    font-weight: bold;
    font-style: normal;
    src:
        url('/assets/fonts/FixelDisplay/FixelDisplay-Bold.woff2') format('woff2'),
        url('/assets/fonts/FixelDisplay/FixelDisplay-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Fixel Display';
    font-weight: 500;
    font-style: normal;
    src:
        url('/assets/fonts/FixelDisplay/FixelDisplay-Medium.woff2') format('woff2'),
        url('/assets/fonts/FixelDisplay/FixelDisplay-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Fixel Display';
    font-weight: bold;
    font-style: italic;
    src:
        url('/assets/fonts/FixelDisplay/FixelDisplay-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/FixelDisplay/FixelDisplay-BoldItalic.otf') format('opentype');
}
